// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Navbar
//
// A simple method to get navigation links to appear in one line.
//
// Markup: navbar.twig
//
// Style guide: navigation.navbar

.navbar,
%navbar {
  margin: 0;
  padding: 0;
  text-align: center;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-auto-rows: 2fr;
  background: #fff;

  @include zen-respond-to(xxs) {
    grid-template-columns: repeat(auto-fit, minmax(138px, 1fr));
  }

  @include zen-respond-to(xs) {
    background: none;
  }

  @include rtl() {
    text-align: right;
  }

  &__item,
  li {
    @include font-size(l);
    list-style-type: none;
    list-style-image: none;
    float: none;
    margin: 0;
    padding: 0;

    a {
      @include padding(.5);
      position: relative;
      z-index: 2;
      display: block;
      height: 100%;
    }

    @include rtl() {
      float: right;
    }

    @include zen-respond-to(m) {
      @include type-layout(xl, 1.5);
    }
  }

  @include zen-respond-to(xs) {
    display: flex;
    justify-content: space-between;
    position: static;
    align-items: center;

    li {
      margin: 0;
      background: none;
    }
  }

  @include zen-respond-to(l) {

    li a {
      font-weight: bold;
    }
  }
}

//
// Drupal selectors.
//

.main-navigation {
  // Hide the block titles put into the main-navigation area of the page.tpl.php.
  .block-menu .block__title,
  .block-menu-block .block__title {
    @extend %visually-hidden;
  }

  // Main menu and secondary menu links and menu block links.
  .links,
  .menu {
    @extend %navbar;
  }
}

.region-navigation .block {

  @include zen-respond-to(xs) {
    background: #f5f5f5;
  }

  &__title {
    @extend %visually-hidden;
  }

  .menu {
    @extend %navbar;
  }
}
