// Dependencies.
@import 'components/clearfix/clearfix';

// Tabs
//
// The primary and secondary tabs.
//
// Markup: tabs.twig
//
// .tabs--secondary - Secondary tabs
//
// Style guide: navigation.tabs

// Tabs (stacked)
//
// When the secondary tabs appear directly after the primary tabs, the styling
// is slightly different.
//
// Markup: tabs--stacked.twig
//
// Style guide: navigation.tabs.stacked

.tabs-wrapper {
  @include clearfix();
  max-width: 1180px;
  margin: 0 auto;
  text-align: center;

  background-color: #f5f5f5;
  background-image: linear-gradient(to top, color(border) 1px, transparent 1px);
  background-color: #fff;
  background-color: transparent;

  @include zen-respond-to(xs) {
    background: none;
  }

  .visually-hidden {
    background-image: linear-gradient(to top, color(border) 1px, transparent 1px);
  }

  & > * {
    @include margin(0 auto);
    max-width: 1140px + $zen-gutters;
  }

  .tabs {
    @include margin-top(.5);
    @include padding(0 .5);
    max-width: 1140px + $zen-gutters;
    float: none;

    & > .tabs__tab {
      @include margin-bottom(.5);
      float: none;

      &.current {
        @include margin-bottom(0);
      }

      @include zen-respond-to(xs) {
        @include margin-bottom(0);
        float: left;
      }
    }

    &.tabs--secondary {
      background-image: linear-gradient(to top, color(border) 1px, transparent 1px);
      display: block;

      & > .tabs__tab {
        margin: 0;
      }
    }
  }

  @if support-for(ie, 9) {
    // IE 9 and earlier don't understand gradients.
    border-bottom: 1px solid color(tabs-border) \0/ie;
  }
}

.page-node-delete .tabs-wrapper {
  display: none;
}

.tabs,
%tabs {
  @extend %clearfix;
  $tab-height: 1.25;
  @include margin((2 - $tab-height) 0 .5);
  @include line-height($tab-height);
  text-transform: uppercase;
  max-width: 1300px + $zen-gutters;

  @if support-for(ie, 9) {
    // IE 9 and earlier don't understand gradients.
    border-bottom: 1px solid color(tabs-border) \0/ie;
  }
  background-image: linear-gradient(to top, color(border) 1px, transparent 1px);
  // The line height of a tab.
  list-style: none;
  white-space: nowrap;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;

  @media print {
    display: none;
  }

  &__tab {
    // Allow the tabs gradient to appear just below the tab.
    border-bottom-color: transparent;
    // Clip the background colors on tab links.
    overflow: hidden;

    @include zen-respond-to(xs) {
      @include font-size(l);
      @include margin-right(.25);
      display: inline-block;
    }

    &:first-child {
      margin-left: 0;
    }

    @if support-for(ie, 9) {
      // IE 9 and earlier don't understand gradients.
      border-bottom: 0 \0/ie;
    }

    @include rtl() {
      float: right;
    }

    &.current,
    &.is-active {
      // Cover the tabs gradient with the same color as the background.
      border-bottom-color: color(tabs-bg);
      border-left-color: color(tabs-border);
      border-right-color: color(tabs-border);
      border-top: 4px solid #0072b9;
    }
  }

  &__tab-link {
    @include padding(.25);
    background: lighten(color(tab-bg), 12%);
    color: color('grey');
    display: block;
    text-decoration: none;
    transition: color .3s, background .3s, border .3s;
    border: 1px solid color(tabs-border);

    @include zen-respond-to(xs) {
      @include padding(0 1.5 .15);
      padding-top: 4px;
    }

    &:focus,
    &:hover {
      background: lighten(color(tab-bg), 5%);
      background: color('white');
      border-color: color(tabs-border);
      text-decoration: none;
      color: color(link);

      @include zen-respond-to(xs) {
        border-bottom: 1px solid color(white);
      }
    }

    &:active,
    &:active:not(.button),
    &.is-active,
    &--is-active {
      color: color(link-visited);
      background: color('white');
      text-shadow: none;
      border-left: 1px solid color(tabs-border);
      border-right: 1px solid color(tabs-border);
      border-bottom: 1px solid color(white);
    }

    &.is-active,
    &--is-active {
      padding-top: 0;
      background: #f5f5f5;
      background: color('white');
    }
  }

  // Turns off .tabs styling.
  &--off {
    @include margin(0);
    @if support-for(ie, 9) {
      border-bottom: 0;
    }
    padding: 0;
    background-image: none;
  }

  &--secondary {
    @include margin-top(1);
    @include font-size(s);
    background-image: none;
  }

  // When secondary tabs appear directly after primary tabs.
  & + &--secondary {
    @include margin-top(0);
    background-image: linear-gradient(to top, color(tabs-border) 1px, transparent 1px);
  }

  &--secondary &__tab {
    @include margin((2 - $tab-height)/2 3px);
    border: 0;
    background: transparent;

    @include rtl() {
      float: right;
    }

    &.is-active {
      border-bottom-color: transparent;
    }
  }

  &--secondary &__tab-link {
    @include margin-right(.25);
    @include margin-bottom(.5);
    @include padding(0 .25);
    border: 1px solid color(tabs-border);
    color: color(tab-secondary);
    background: lighten(color(tab-bg), 8%);
    letter-spacing: normal;

    &:focus,
    &:hover {
      color: darken(color(tab-secondary), 20%);
      background: color(tab-bg);
      border-color: darken(color(tab-bg), 26.5%);
    }

    &:active,
    &.is-active,
    &--is-active {
      color: lighten(color(tab-bg), 15%);
      text-shadow: darken(color(tab-secondary), 20%) 0 1px 0;
      background: color(tab-secondary);
      border-color: darken(color(tab-secondary), 40%);
    }
  }
}

//
// Drupal selectors.
//

// Views mis-uses the theme hooks for tabs.
.views-displays .secondary {
  @extend %tabs--off;
}

.vertical-tabs {
  background: #fff;

  fieldset.vertical-tabs-pane {
    @include padding-top(1);
    @include padding-bottom(1);
  }
}

div.horizontal-tabs {
  background: #fff;
}
