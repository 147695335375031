#block-iorgos-core-whatsapp,
#block-iorgos-core-phone {
  @include margin(1 0 .5);
  text-align: center;
  clear: both;

  @include zen-respond-to(xs) {
    @include margin(1 1 .5 .5);
  }

  .inner {
    font-size: 0;

    & > a {
      @include font-size(xl);
      font-weight: bold;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        cursor: default;
      }

      &::before {
        @include font-size(xl);
        font-family: 'Font Awesome 5 Free';
        content: '\f879';
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  @include zen-respond-to(m) {
    clear: none;
    float: right;
  }
}

#block-iorgos-core-whatsapp .inner > a::before {
  font-family: 'Font Awesome 5 Brands';
  content: '\f232';
  font-weight: normal;
}

%social {

  li {
    display: inline-block;
    margin: 0 10px;

    &.facebook a::before {
      content: '\f09a';
    }

    &.instagram a::before {
      content: '\f16d';
    }

    &.twitter a::before {
      content: '\f099';
    }

    &.whatsapp a::before {
      content: '\f232';
    }
  }

  a {
    font-size: 0;
    display: block;
    height: 32px;

    &:hover {
      color: lighten(color(link), 10);
    }

    &::before {
      font-family: 'Font Awesome 5 Brands';
      display: inline-block;
      font-size: 32px;
      line-height: 32px;
    }
  }

  @include zen-respond-to(m) {

    li {
      margin: 0 0 0 10px;
    }
  }
}

.node-property {
  position: relative;
}

.share {
  @extend %social;
  @include margin-bottom(1);
  text-align: center;
  border: 0;
  padding: 10px;
  background: #fff;

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  h3 {
    margin: 0;
    display: inline-block;
    line-height: 32px;
    vertical-align: top;
  }
}

.social-networks {
  @extend %social;
  @include margin(0 0 1);
  padding: 0;
  text-align: center;

  @include zen-respond-to(m) {
    @include margin(0 1 1 0);
    // float: right;
    // clear: right;
  }
}

.region-help .block {
  @include clearfix();
  @include margin-bottom(1);
  @include padding(.5 1 0);
  @include font-size(xl);
  background: #f5f5f5;
  border-radius: 4px;
  border: 1px solid color('white');

  .inner {
    @include margin-bottom(-.5);
  }
}

#realty-buscador-form,
#iorgos-realty-core-buscador-form {

  .form-item {
    display: inline-block;
  }
}

.page-buscador main > .content {
    padding: 0;
    background: none;
    border: 0;
    box-shadow: none;
}

.view-empty p {
  @include margin-top(1);
}

.media--loading .media__element {
  display: none;
}

.paragraphs-item-imagen {
  @include margin-bottom(1);

  text-align: center;
}

.block-mindicador .inner {

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    @include font-size(m);

    list-style: none;
    display: inline-block;
    margin: 0 5px;
  }
}
