.view-articulos .view-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));

  // @media screen and (min-width: 484px) {
  //   grid-auto-rows: 1fr;
  // }

  h2 {
    margin: 0;
  }

  .views-row article {
    background: #fff;

    & > * {
      padding: 16px;

      &.field-name-field-photos {
        padding: 0;

        img {
          width: 100%;
        }
      }
    }
  }

  .links.inline {
    margin: 0;
  }
}
