.flexslider {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  border: 1px solid #d5d5d4;
  box-shadow: 0 0 8px rgba(0, 0, 0, .1);
}

.flex-caption {
  text-align: center;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .25);
  font-weight: bold;
  color: color('white');
  text-shadow: 0 0 3px #000;
}

.flex-control-nav {
  bottom: 0;
  z-index: 2;
}

.flex-control-paging li a {
  background: rgba(200, 200, 200, .5);

  &.flex-active {
    background: color('white');
  }
}
