// sass-lint:disable no-vendor-prefixes

// Use the saner border-box model for all elements.
* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  @include define-type-sizing();
  // Change the default font family in all browsers (opinionated).
  @include typeface(body);
  // Prevent adjustments of font size after orientation changes in IE and iOS.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  min-height: 100%;
}

body {
  // @include typey-debug-grid();
  min-width: 320px;
  margin: 0;
  padding: 0;
  color: color(text);
  background-color: color('grey');
  display: grid;
  grid-template-rows: 0 max-content auto max-content;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  &.toolbar {
    grid-template-rows: 0 max-content max-content auto max-content;
  }

  .main {
    display: grid;
    grid-template-rows: max-content auto;
    background: image-url('layouts', 'layout-swap/images/background.png') repeat-x top;

    @include zen-respond-to(xs) {

      main {
        grid-row: 2;
      }

      .navigation {
        grid-row: 1;
      }
    }
  }

  &.page-portada main .content {
    background: none;
    padding: 0;
  }
}

#features-export-wrapper .component {

  &-added,
  &-detected,
  &-included {

    .form-type-checkbox {
      line-height: 100%;
    }
  }
}
