// Font faces
//
// Instead of relying on the fonts that are available on a user's computer, you
// can use web fonts which, like images, are resources downloaded to the user's
// browser. Because of the bandwidth and rendering resources required, web fonts
// should be used with care.
//
// Numerous resources for web fonts can be found on Google. Here are a few
// websites where you can find Open Source fonts to download:
// - http://www.fontsquirrel.com/fontface
// - http://www.theleagueofmoveabletype.com
//
// In order to use these fonts, you will need to convert them into formats
// suitable for web fonts. We recommend the free-to-use Font Squirrel's
// Font-Face Generator:
//   http://www.fontsquirrel.com/fontface/generator
//
// The following is an example @font-face declaration. This font can then be
// used in any ruleset using a property like this:  font-family: Example, serif;
//
// Since we're using Sass, you'll need to declare your font faces here, then you
// can add them to the font variables in the _init.scss partial.

// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans_regular/OpenSans-Regular-webfont.eot');
  src: url('../fonts/opensans_regular/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans_regular/OpenSans-Regular-webfont.woff') format('woff'),
       url('../fonts/opensans_regular/OpenSans-Regular-webfont.ttf') format('truetype'),
       url('../fonts/opensans_regular/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans_italic/OpenSans-Italic-webfont.eot');
  src: url('../fonts/opensans_italic/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans_italic/OpenSans-Italic-webfont.woff') format('woff'),
       url('../fonts/opensans_italic/OpenSans-Italic-webfont.ttf') format('truetype'),
       url('../fonts/opensans_italic/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.eot');
  src: url('../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.woff') format('woff'),
       url('../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
       url('../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans_bold/OpenSans-Bold-webfont.eot');
  src: url('../fonts/opensans_bold/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans_bold/OpenSans-Bold-webfont.woff') format('woff'),
       url('../fonts/opensans_bold/OpenSans-Bold-webfont.ttf') format('truetype'),
       url('../fonts/opensans_bold/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('../fonts/opensans_condensed/OpenSans-CondLight-webfont.eot');
  src: url('../fonts/opensans_condensed/OpenSans-CondLight-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/opensans_condensed/OpenSans-CondLight-webfont.woff') format('woff'),
       url('../fonts/opensans_condensed/OpenSans-CondLight-webfont.ttf') format('truetype'),
       url('../fonts/opensans_condensed/OpenSans-CondLight-webfont.svg#open_sanscondensed_light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/font_awesome/fa-brands-400.eot');
  src: url('../fonts/font_awesome/fa-brands-400.eot?#iefix') format('embedded-opentype'),
       url('../fonts/font_awesome/fa-brands-400.woff2') format('woff2'),
       url('../fonts/font_awesome/fa-brands-400.woff') format('woff'),
       url('../fonts/font_awesome/fa-brands-400.ttf') format('truetype'),
       url('../fonts/font_awesome/fa-brands-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/font_awesome/fa-regular-400.eot');
  src: url('../fonts/font_awesome/fa-regular-400.eot?#iefix') format('embedded-opentype'),
       url('../fonts/font_awesome/fa-regular-400.woff2') format('woff2'),
       url('../fonts/font_awesome/fa-regular-400.woff') format('woff'),
       url('../fonts/font_awesome/fa-regular-400.ttf') format('truetype'),
       url('../fonts/font_awesome/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/font_awesome/fa-solid-900.eot');
  src: url('../fonts/font_awesome/fa-solid-900.eot?#iefix') format('embedded-opentype'),
       url('../fonts/font_awesome/fa-solid-900.woff2') format('woff2'),
       url('../fonts/font_awesome/fa-solid-900.woff') format('woff'),
       url('../fonts/font_awesome/fa-solid-900.ttf') format('truetype'),
       url('../fonts/font_awesome/fa-solid-900.svg#fontawesome') format('svg');
}
