.buscador {

  margin: 0;
  padding: 0;
  border: 0;

  a {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    @include zen-respond-to(xxxs) {
      color: #333;
    }
  }

  &.collapsed {
    background: none;

    a {
      background: #fff;

      @include zen-respond-to(xxxs) {
        background: none;
      }
    }
  }

  legend {
    text-align: center;
    display: block;
    margin: 0;
    width: 100%;

    @include zen-respond-to(xxxs) {
      text-align: left;
    }

    .fieldset-legend {
      background-image: none;
      padding: 0;
    }

    a {
      @include padding(0 .5);
      display: block;

      @include zen-respond-to(xxxs) {
        background: none;
      }
    }
  }

  .fieldset-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    @media screen and (min-width: 354px) {
      padding: 10px;
      background: rgba(255, 255, 255, .75);
    }

    @media screen and (min-width: 1044px) {
      grid-gap: 20px;
    }
  }

  .views-exposed-form label {
    @include visually-hidden();
    color: color('white');
    text-align: left;
  }

  .form-item {
    display: block;
    float: none;
    margin-bottom: 0;
    padding-right: 0;

    &.form-type-select select {
      width: 100%;
    }
  }

  .form-type-select {
    position: relative;

    &::after {
      top: 0;
      line-height: 50px;
      height: 50px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f107';
      display: block;
      position: absolute;
      right: 15px;
      pointer-events: none;
      color: color(link);

      @include zen-respond-to(s) {
        height: 41px;
        line-height: 41px;
      }
    }
  }

  select {
    @include padding(0 .25);
    appearance: none; /* remove default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 50px;
    line-height: 50px;
    color: #666;
    background: #f3f3f3;
    background: #fff;
    border: 1px solid #aaa;

    @include zen-respond-to(s) {
      height: 41px;
      line-height: 41px;
    }

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      background: #fff;
      border: 1px solid color(link);
      color: #000;
    }

    &::-ms-expand {
      display: none; /* hide the default arrow in ie10 and ie11 */
    }
  }

  .form-submit {
    @include font-size(xl);
    padding: 10px 1.5rem;
    font-weight: bold;
    background: color(link);
    color: #fff;
    border: 0;
    display: block;
    width: 100%;

    &:hover,
    &:focus {
     background: lighten(color(link), 10);
    }

    @include zen-respond-to(xxxs) {
      padding: 8px 0;
    }
  }
}

.front .buscador {
  @include margin-top(1);
}
