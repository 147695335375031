%title-field {
  margin-left: 0;
  font-weight: bold;
  padding: 0;

  .field-content {
    @include font-size(xl);
    text-align: center;
    margin: 0;
  }
}

%photos-field {

  a {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 240px;
    background: #ddd;

    @include zen-respond-to(l) {
      min-height: 120px;
    }
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  @include zen-respond-to(xxs) {
    display: block;
    clear: both;
    margin-bottom: 10px;
  }
}

%useful-field {

  @include zen-respond-to(xxs) {
    width: auto;
  }
}

%total-field {

  @include zen-respond-to(xxs) {
    width: auto;
  }
}

%bedrooms-field {

  .field-items::after,
  .field-content::after {
    content: '\f236';
  }
}

%bathrooms-field {
  //width: 3em;

  .field-content::after {
    content: '\f2cd';
  }

  @include zen-respond-to(xxs) {
    width: auto;
  }
}

%valor-field {
  @include font-size(m);
  color: color('grey');
  text-transform: uppercase;
  text-align: center;

  //display: inline;

  padding: 0;
  margin: 0 0 10px;
  //float: right;
  //width: auto;

  .field-content {
    //@include typeface(body);
    font-family: 'Open Sans';
    font-weight: bold;
    text-align: center;
    //margin-left: 15px;

    a {
      @include font-size(m);
    }
  }
}

%parking-field .field-content::after {
  content: '\f1b9';
}

%cellar-field .field-content::after {
  content: '\f0d1';
}

%comuna-field {
  @include font-size(s);
  display: block;
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 1;
  background: rgba(0, 0, 0, .25);
  padding: 0 .25em;
  color: #fff;
  text-shadow: 0 0 3px #000;

  .field-content {
    text-align: center;

    a {
      color: #fff;
    }
  }
}

.front.page-node,
.page-views {

  main > .content {
    padding: 0;
    background: none;
    border: 0;
    box-shadow: none;
  }

  &.section-node main > .content {
    padding: 1.5rem;
    background: #fff;
  }
}



.node-type-property,
.node-type-estate {

  .layout-3col__full > .wrapper {
    margin-bottom: 0;
    background: none;
    padding: 0;
    border: 0;
    box-shadow: none;
  }

  .group-wrapper.field-group-div {
    @include clearfix();
    width: auto;
    margin: 0 -10px;

    h3 {
      text-align: center;
      margin: 20px 10px 10px;
      text-transform: uppercase;
      background: #f8f8f8;
    }

    & > .field-group-div {

      & > div {
        @include padding(1);
        border: 1px solid silver;
        background: #f5f5f5;
        margin: 0 10px 20px;
      }

      @include zen-respond-to(xxs) {
        width: 50%;
        float: left;
      }

      @include zen-respond-to(s) {
        width: (100% / 3);
      }

      .field.field-label-inline {

        .field-items {
          display: block;
          text-align: right;
        }
      }

      .field-label-inline .field-label {
        float: left;
      }
    }
  }

  .field-name-body,
  .customer {
     @include padding(1 1 0);
     background: rgba(255, 255, 255, .75);
     border: 1px solid #d5d5d4;
     box-shadow: 0 0 8px rgba(0, 0, 0, .1);
 }

 .field-name-body {
   // @include padding(0);
   @include margin-bottom(0);
   clear: both;
   // background: none;
   // border: 0;
  }

  .field-name-field-mapa .field-item {
    height: 400px;
  }
}

.page-node-edit.node-type-property,
.page-node-edit.node-type-estate {

  .field-name-body {
    padding: 0;
    background: none;
    border: 0;
    box-shadow: none;
  }
}

.field-name-field-paragraph {
  @include font-size(xl);

  @include zen-respond-to(xs) {
    @include font-size(l);
  }

  .paragraphs-item-texto {
    @include margin-bottom(1);
  }
}

.field-name-field-bedrooms {
  //@extend %bedrooms-field;

  @include zen-respond-to(xxxs) {
    width: auto;
  }

  .field-items {
    display: block;
    text-align: right;

    &::after {
      width: 45px;
      margin-left: .25em;
      color: color('grey-dark');

      @include zen-respond-to(xxxl) {
        @include font-size(s);
      }
    }
  }
}

.view-propiedades-destacadas {

  .grid__content {
    background: #fff;
    padding: 15px;
  }

  h2 {
    margin: 0 0 10px;
  }

  .views-field-field-price {
    height: 24px;
    margin: 0 0 16px;
    font-family: 'Open Sans';
    font-weight: bold;
  }

  .slide__grid {
    padding-bottom: 0;
  }
}

.view-buscador,
.view-property-list {
  margin-top: 20px;

  .view-filters {
    background: color('white');
  }

  .view-content {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

    @media screen and (min-width: 484px) {
      grid-auto-rows: 1fr;
    }
  }

  .views-row {
    // clear: both;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #d5d5d4;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);

    .inner {
      @include clearfix();
      position: relative;
      padding: 10px 15px 5px;
      width: auto;
    }
  }

  .views-label {
    @include font-size(xl);
    color: #777;
    float: left;
    margin-right: 10px;

    @include zen-respond-to(xs) {
      @include font-size(l);
    }
  }

  .field-content {
    @include font-size(xl);
    @include margin-bottom(0, m);

    font-weight: 600;
    min-height: $base-line-height;
    text-align: right;
    //color: color('grey-dark');
    //@include typeface(condensed);

    @include zen-respond-to(xs) {
      @include font-size(l);
    }

    &::after {
      width: 45px;
      margin-left: .25em;
      color: color('grey-dark');

      @include zen-respond-to(xxxl) {
        @include font-size(s);
      }
    }
  }
}
