// Inline sibling
//
// An element that needs to appear inline with the content that follows.
//
// Markup: inline-sibling.twig
//
// Style guide: components.inline-sibling

.inline-sibling,
%inline-sibling {
  @include margin-right(.25);
  display: inline;

  @include rtl() {
    margin-right: 0;
    margin-left: 10px;
  }

  // All of the children of the element need to be inline as well.
  &__child,
  * {
    display: inline;
  }

  // The next element (and its first-child element, if the markup is nested)
  // needs to be inline.
  &__adjacent,
  + *,
  + * > :first-child,
  + * > :first-child > :first-child {
    display: inline;
  }
}

//
// Drupal selectors.
//

// Drupal core.
.field-label-inline {
  .field-label {
    @extend %inline-sibling;
  }

  // Revert core's ridiculous styling without polluting the component.
  .field-label,
  .field-items {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
}

// The inline field label used by the Fences 7.x-1.x module.
span.field-label {
  @extend %inline-sibling;
}

.field .field-label {
  font-weight: normal;
}

.field-label {

  &-inline,
  &-above {

    .field-items {
      @include font-size(l);
      font-weight: bold;
      color: #000;
    }
  }
}

#edit-points,
#edit-amount {
  text-align: right;
}

.form-field-name-field-estate-seller {

  @include zen-respond-to(m) {
    float: right;
    width: 50%;
  }
}

.fieldset-wrapper > .form-type-textfield > input.form-text,
.field-type-email .form-type-textfield > input.form-text {
  width: 100%;
}
