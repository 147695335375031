@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans_regular/OpenSans-Regular-webfont.eot");
  src: url("../fonts/opensans_regular/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans_regular/OpenSans-Regular-webfont.woff") format("woff"), url("../fonts/opensans_regular/OpenSans-Regular-webfont.ttf") format("truetype"), url("../fonts/opensans_regular/OpenSans-Regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans_italic/OpenSans-Italic-webfont.eot");
  src: url("../fonts/opensans_italic/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans_italic/OpenSans-Italic-webfont.woff") format("woff"), url("../fonts/opensans_italic/OpenSans-Italic-webfont.ttf") format("truetype"), url("../fonts/opensans_italic/OpenSans-Italic-webfont.svg#open_sansitalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.eot");
  src: url("../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.woff") format("woff"), url("../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.ttf") format("truetype"), url("../fonts/opensans_bolditalic/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans_bold/OpenSans-Bold-webfont.eot");
  src: url("../fonts/opensans_bold/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans_bold/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/opensans_bold/OpenSans-Bold-webfont.ttf") format("truetype"), url("../fonts/opensans_bold/OpenSans-Bold-webfont.svg#open_sansbold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url("../fonts/opensans_condensed/OpenSans-CondLight-webfont.eot");
  src: url("../fonts/opensans_condensed/OpenSans-CondLight-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans_condensed/OpenSans-CondLight-webfont.woff") format("woff"), url("../fonts/opensans_condensed/OpenSans-CondLight-webfont.ttf") format("truetype"), url("../fonts/opensans_condensed/OpenSans-CondLight-webfont.svg#open_sanscondensed_light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/font_awesome/fa-brands-400.eot");
  src: url("../fonts/font_awesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/font_awesome/fa-brands-400.woff2") format("woff2"), url("../fonts/font_awesome/fa-brands-400.woff") format("woff"), url("../fonts/font_awesome/fa-brands-400.ttf") format("truetype"), url("../fonts/font_awesome/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/font_awesome/fa-regular-400.eot");
  src: url("../fonts/font_awesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/font_awesome/fa-regular-400.woff2") format("woff2"), url("../fonts/font_awesome/fa-regular-400.woff") format("woff"), url("../fonts/font_awesome/fa-regular-400.ttf") format("truetype"), url("../fonts/font_awesome/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/font_awesome/fa-solid-900.eot");
  src: url("../fonts/font_awesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/font_awesome/fa-solid-900.woff2") format("woff2"), url("../fonts/font_awesome/fa-solid-900.woff") format("woff"), url("../fonts/font_awesome/fa-solid-900.ttf") format("truetype"), url("../fonts/font_awesome/fa-solid-900.svg#fontawesome") format("svg");
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: "Open Sans Condensed", "Open Sans", "Verdana", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #999999;
  display: grid;
  grid-template-rows: 0 max-content auto max-content;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

body.toolbar {
  grid-template-rows: 0 max-content max-content auto max-content;
}

body .main {
  display: grid;
  grid-template-rows: max-content auto;
  background: url(../sass/layouts/layout-swap/images/background.png) repeat-x top;
}

@media (min-width: 555px) {
  body .main main {
    grid-row: 2;
  }
  body .main .navigation {
    grid-row: 1;
  }
}

body.page-portada main .content {
  background: none;
  padding: 0;
}

#features-export-wrapper .component-added .form-type-checkbox, #features-export-wrapper .component-detected .form-type-checkbox, #features-export-wrapper .component-included .form-type-checkbox {
  line-height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

main {
  padding: 0 0.75rem 1.5rem;
}

@media (min-width: 1222px) {
  main {
    padding: 0 0 1.5rem;
  }
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
}

:visited {
  color: #0062a0;
}

a:hover,
a:focus {
  text-decoration: underline;
}

a:active {
  color: #0082d3;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

ul.action-links {
  @inlcude clearfix();
  margin: 0.75rem 0;
  padding: 0;
  font-weight: 900;
  list-style-type: none;
}

ul.action-links li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}

ul.action-links a {
  padding: 0.375rem 0.75rem;
  margin: 4px auto;
  border-radius: 5px;
  display: block;
  transition: no-transition-all, .3s;
  background: #ecf8ff;
  border: 2px solid #0072b9;
  color: #0072b9;
  border-color: #006fb4;
}

ul.action-links a:hover {
  text-decoration: none;
  background: #0074bd;
  color: #fff;
}

th a {
  color: #fff;
  text-decoration: underline;
}

th a:hover {
  text-decoration: none;
}

footer a {
  white-space: nowrap;
}

h1 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-top: 0.375rem;
  margin-bottom: 0.75rem;
  margin-top: 0;
}

@media (min-width: 555px) {
  h1 {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}

main h1 {
  color: #fff;
  text-shadow: 0 0 5px #000;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.field-name-body h2:first-child {
  margin-top: 0;
}

.views-row h2 {
  margin-top: 0;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.admin-panel h3 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 0;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #333;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 0.75rem 0 1.5rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

li {
  margin-bottom: 0.375rem;
}

@media (min-width: 777px) {
  li {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 777px) {
  .field-name-body li {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
}

@media (min-width: 777px) {
  dt {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

.admin-list dd {
  padding-bottom: 1.5rem;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 0 0 1.5rem;
}

@media (min-width: 777px) {
  p,
  pre {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.field-name-body img.center {
  margin: 0 auto;
  display: block;
}

.field-name-body img.left {
  margin-right: 1.5rem;
  float: left;
}

.field-name-body img.right {
  margin-left: 1.5rem;
  float: right;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

input.form-text {
  padding: 0.375rem;
  width: 100%;
}

@media (min-width: 666px) {
  input.form-text {
    width: auto;
  }
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #333;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #333;
}

[type='submit'] {
  font-size: 1.5rem;
  padding: 0.375rem 1.5rem;
  font-weight: bold;
  color: #fff;
  background: #0072b9;
  border: 0;
}

[type='submit']:hover {
  background: #0091ec;
  color: #fff;
}

[value='Eliminar'] {
  background: #c00;
}

[value='Eliminar']:hover {
  background: red;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  margin: 0 0 1.5rem;
  border: 1px solid #cccccc;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 .2rem 0 0;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
  background: #eeeeee;
}

[type='text'],
[type='search'],
[type='tel'],
[type='url'],
[type='email'],
[type='password'],
[type='date'],
[type='time'],
[type='number'],
textarea {
  border: 1px solid #cccccc;
  background: #eeeeee;
}

.password-strength {
  width: auto;
  float: none;
  position: absolute;
  left: .75rem;
  right: .75rem;
  margin-top: 72px;
}

@media (min-width: 777px) {
  .password-strength {
    position: static;
    width: 17em;
    float: right;
    margin-top: 1.4em;
  }
}

.confirm-parent,
.password-parent {
  width: auto;
}

@media (min-width: 555px) {
  .confirm-parent,
  .password-parent {
    width: 36.3em;
  }
}

div.password-confirm {
  float: none;
  width: auto;
}

@media (min-width: 777px) {
  div.password-confirm {
    float: right;
    width: 17em;
  }
}

.form-actions {
  margin-top: 1.5rem;
  text-align: center;
}

@media (min-width: 555px) {
  .form-actions {
    text-align: left;
  }
}

.form-actions .form-submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 555px) {
  .form-actions .form-submit {
    margin-right: 0.75rem;
    display: inline-block;
  }
}

table {
  margin: 0 0 1.5rem;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #cccccc;
}

td,
th {
  padding: .25rem;
}

td.checkbox,
th.checkbox {
  text-align: center;
}

@media (min-width: 777px) {
  td,
  th {
    font-weight: normal;
  }
}

thead th,
tfoot th {
  font-weight: normal;
  text-align: left;
}

thead th {
  color: #fff;
  background: #0072b9;
}

tbody tr {
  background-color: #eeeeee;
}

tbody tr.even {
  background-color: #fff;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  padding: 0;
  max-width: 1165px;
  max-width: none;
}

.layout-center--shared-grid {
  padding-left: 0;
  padding-right: 0;
}

main > .content,
.maintenance-page .main-content,
#progress,
.admin,
.cart-empty-page,
.sticky-header,
.sticky-enabled,
.term-listing-heading,
.region-help .block,
.contextual-links-wrapper,
.system-status-report,
.action-links-wrapper {
  margin: 0 auto;
  padding: 0 0.75rem;
  max-width: 1180px;
}

.messages-wrapper {
  margin: 0 auto;
  max-width: 1180px;
}

main > .content {
  padding: 1.5rem;
  background: #fff;
}

.wrapper-title {
  margin: 0 auto;
  padding-top: 0.375rem;
}

@media (min-width: 1222px) {
  .wrapper-title {
    max-width: 1180px;
  }
}

#views-ui-edit-form,
#views-preview-wrapper {
  margin: 0 1.5rem;
}

#edit-options-alter-help-wrapper {
  float: none;
}

#features-form-table {
  margin: 1.5rem 0;
}

main > .profile {
  margin-top: 1.5rem;
}

div.krumo-root,
main > form,
.section-admin .admin-list,
.admin-list {
  padding: 0 0.75rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px;
}

.entity-pos,
#update-manager-update-form,
.confirmation {
  margin-top: 1.5rem;
}

.wizard-trail {
  font-size: 0.875rem;
  margin: 0.75rem 0;
}

.maintenance-page .header__site-link:link, .maintenance-page .header__site-link:visited {
  color: #333;
}

.region-help {
  padding-top: 1.5rem;
  font-size: 1.25rem;
}

.layout-swap {
  position: relative;
}

.layout-swap::before {
  content: '';
  display: block;
  height: 250px;
  background: url(../sass/layouts/layout-swap/images/background.png) repeat-x top;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

@media (min-width: 555px) {
  .layout-swap::before {
    top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    line-height: 48px;
    height: 48px;
    width: 100%;
    background: #f5f5f5;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #333;
}

.box--highlight {
  border-color: #0072b9;
}

.ctools-button-processed {
  font-size: inherit;
}

#views-display-menu-tabs {
  background: none;
}

.profile {
  margin: 0;
}

div.admin .right,
div.admin .left {
  width: 49%;
  margin: 0;
}

div.admin-panel {
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background: #f5f5f5;
}

div.admin-panel::before, div.admin-panel::after {
  content: '';
  display: table;
}

div.admin-panel::after {
  clear: both;
}

.tabs-wrapper + .content {
  margin-top: 1.5rem;
}

#block-iorgos-core-whatsapp,
#block-iorgos-core-phone {
  margin: 1.5rem 0 0.75rem;
  text-align: center;
  clear: both;
}

@media (min-width: 555px) {
  #block-iorgos-core-whatsapp,
  #block-iorgos-core-phone {
    margin: 1.5rem 1.5rem 0.75rem 0.75rem;
  }
}

#block-iorgos-core-whatsapp .inner,
#block-iorgos-core-phone .inner {
  font-size: 0;
}

#block-iorgos-core-whatsapp .inner > a,
#block-iorgos-core-phone .inner > a {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
}

#block-iorgos-core-whatsapp .inner > a:hover,
#block-iorgos-core-phone .inner > a:hover {
  text-decoration: none;
  cursor: default;
}

#block-iorgos-core-whatsapp .inner > a::before,
#block-iorgos-core-phone .inner > a::before {
  font-size: 1.5rem;
  font-family: 'Font Awesome 5 Free';
  content: '\f879';
  font-weight: bold;
  margin-right: 10px;
}

@media (min-width: 777px) {
  #block-iorgos-core-whatsapp,
  #block-iorgos-core-phone {
    clear: none;
    float: right;
  }
}

#block-iorgos-core-whatsapp .inner > a::before {
  font-family: 'Font Awesome 5 Brands';
  content: '\f232';
  font-weight: normal;
}

.share li, .social-networks li {
  display: inline-block;
  margin: 0 10px;
}

.share li.facebook a::before, .social-networks li.facebook a::before {
  content: '\f09a';
}

.share li.instagram a::before, .social-networks li.instagram a::before {
  content: '\f16d';
}

.share li.twitter a::before, .social-networks li.twitter a::before {
  content: '\f099';
}

.share li.whatsapp a::before, .social-networks li.whatsapp a::before {
  content: '\f232';
}

.share a, .social-networks a {
  font-size: 0;
  display: block;
  height: 32px;
}

.share a:hover, .social-networks a:hover {
  color: #0091ec;
}

.share a::before, .social-networks a::before {
  font-family: 'Font Awesome 5 Brands';
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
}

@media (min-width: 777px) {
  .share li, .social-networks li {
    margin: 0 0 0 10px;
  }
}

.node-property {
  position: relative;
}

.share {
  margin-bottom: 1.5rem;
  text-align: center;
  border: 0;
  padding: 10px;
  background: #fff;
}

.share ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.share h3 {
  margin: 0;
  display: inline-block;
  line-height: 32px;
  vertical-align: top;
}

.social-networks {
  margin: 0 0 1.5rem;
  padding: 0;
  text-align: center;
}

@media (min-width: 777px) {
  .social-networks {
    margin: 0 1.5rem 1.5rem 0;
  }
}

.region-help .block {
  margin-bottom: 1.5rem;
  padding: 0.75rem 1.5rem 0;
  font-size: 1.5rem;
  background: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #fff;
}

.region-help .block::before, .region-help .block::after {
  content: '';
  display: table;
}

.region-help .block::after {
  clear: both;
}

.region-help .block .inner {
  margin-bottom: -0.75rem;
}

#realty-buscador-form .form-item,
#iorgos-realty-core-buscador-form .form-item {
  display: inline-block;
}

.page-buscador main > .content {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.view-empty p {
  margin-top: 1.5rem;
}

.media--loading .media__element {
  display: none;
}

.paragraphs-item-imagen {
  margin-bottom: 1.5rem;
  text-align: center;
}

.block-mindicador .inner ul {
  margin: 0;
  padding: 0;
}

.block-mindicador .inner li {
  font-size: 1rem;
  list-style: none;
  display: inline-block;
  margin: 0 5px;
}

.clearfix::before, .clearfix::after,
.tabs::before,
.tabs::after {
  content: '';
  display: table;
}

.clearfix::after,
.tabs::after {
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header-wrapper {
  background: #999999;
}

.header {
  display: grid;
  grid-template-columns: auto max-content max-content auto;
  align-items: center;
  max-width: 1200px;
  min-height: 80px;
  margin: 0 auto;
  padding-bottom: 15px;
}

@media (min-width: 555px) {
  .header {
    grid-template-columns: max-content auto max-content;
    padding-bottom: 0;
  }
}

.header__logo {
  margin: 0.75rem;
  width: 64px;
  grid-column: 2;
  justify-self: end;
}

@media (min-width: 555px) {
  .header__logo {
    width: auto;
    grid-column: 1;
    justify-self: end;
    justify-self: unset;
  }
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  margin: 0;
  vertical-align: bottom;
}

.header__name-and-slogan {
  align-self: center;
}

.header__site-name {
  margin: 0;
  padding: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #333;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__region {
  grid-column: 1 / -1;
}

@media (min-width: 555px) {
  .header__region {
    grid-column: 3;
  }
}

.header__secondary-menu {
  float: right;
  position: relative;
  z-index: 2;
}

.header__secondary-menu ul {
  margin: 0.75rem 0;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

footer {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.375rem 0;
  text-align: center;
  background: #999;
}

footer p {
  font-size: 1rem;
  line-height: 1.5rem;
}

footer p:last-of-type {
  margin-bottom: 0;
}

.footer {
  font-size: 0.875rem;
}

@media (min-width: 555px) {
  .footer {
    font-size: 1rem;
  }
}

.view-buscador .views-field-field-contract {
  margin-left: 0;
  font-weight: bold;
  padding: 0;
}

.view-buscador .views-field-field-contract .field-content {
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
}

.view-buscador .views-field-field-photos a {
  position: relative;
  overflow: hidden;
  display: block;
  min-height: 240px;
  background: #ddd;
}

@media (min-width: 888px) {
  .view-buscador .views-field-field-photos a {
    min-height: 120px;
  }
}

.view-buscador .views-field-field-photos img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

@media (min-width: 444px) {
  .view-buscador .views-field-field-photos {
    display: block;
    clear: both;
    margin-bottom: 10px;
  }
}

@media (min-width: 444px) {
  .view-buscador .views-field-field-useful-sqm {
    width: auto;
  }
}

@media (min-width: 444px) {
  .view-buscador .views-field-field-total-sqm {
    width: auto;
  }
}

.view-buscador .views-field-field-currency {
  font-size: 1rem;
  color: #999999;
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  margin: 0 0 10px;
}

.view-buscador .views-field-field-currency .field-content {
  font-family: 'Open Sans';
  font-weight: bold;
  text-align: center;
}

.view-buscador .views-field-field-currency .field-content a {
  font-size: 1rem;
}

.view-buscador .views-field-field-estate-address, .view-buscador .views-field-field-commune {
  font-size: 0.875rem;
  display: block;
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.25);
  padding: 0 .25em;
  color: #fff;
  text-shadow: 0 0 3px #000;
}

.view-buscador .views-field-field-estate-address .field-content, .view-buscador .views-field-field-commune .field-content {
  text-align: center;
}

.view-buscador .views-field-field-estate-address .field-content a, .view-buscador .views-field-field-commune .field-content a {
  color: #fff;
}

.front.page-node main > .content,
.page-views main > .content {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.front.page-node.section-node main > .content,
.page-views.section-node main > .content {
  padding: 1.5rem;
  background: #fff;
}

.node-type-property .layout-3col__full > .wrapper,
.node-type-estate .layout-3col__full > .wrapper {
  margin-bottom: 0;
  background: none;
  padding: 0;
  border: 0;
  box-shadow: none;
}

.node-type-property .group-wrapper.field-group-div,
.node-type-estate .group-wrapper.field-group-div {
  width: auto;
  margin: 0 -10px;
}

.node-type-property .group-wrapper.field-group-div::before, .node-type-property .group-wrapper.field-group-div::after,
.node-type-estate .group-wrapper.field-group-div::before,
.node-type-estate .group-wrapper.field-group-div::after {
  content: '';
  display: table;
}

.node-type-property .group-wrapper.field-group-div::after,
.node-type-estate .group-wrapper.field-group-div::after {
  clear: both;
}

.node-type-property .group-wrapper.field-group-div h3,
.node-type-estate .group-wrapper.field-group-div h3 {
  text-align: center;
  margin: 20px 10px 10px;
  text-transform: uppercase;
  background: #f8f8f8;
}

.node-type-property .group-wrapper.field-group-div > .field-group-div > div,
.node-type-estate .group-wrapper.field-group-div > .field-group-div > div {
  padding: 1.5rem;
  border: 1px solid silver;
  background: #f5f5f5;
  margin: 0 10px 20px;
}

@media (min-width: 444px) {
  .node-type-property .group-wrapper.field-group-div > .field-group-div,
  .node-type-estate .group-wrapper.field-group-div > .field-group-div {
    width: 50%;
    float: left;
  }
}

@media (min-width: 666px) {
  .node-type-property .group-wrapper.field-group-div > .field-group-div,
  .node-type-estate .group-wrapper.field-group-div > .field-group-div {
    width: 33.33333%;
  }
}

.node-type-property .group-wrapper.field-group-div > .field-group-div .field.field-label-inline .field-items,
.node-type-estate .group-wrapper.field-group-div > .field-group-div .field.field-label-inline .field-items {
  display: block;
  text-align: right;
}

.node-type-property .group-wrapper.field-group-div > .field-group-div .field-label-inline .field-label,
.node-type-estate .group-wrapper.field-group-div > .field-group-div .field-label-inline .field-label {
  float: left;
}

.node-type-property .field-name-body,
.node-type-property .customer,
.node-type-estate .field-name-body,
.node-type-estate .customer {
  padding: 1.5rem 1.5rem 0;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #d5d5d4;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.node-type-property .field-name-body,
.node-type-estate .field-name-body {
  margin-bottom: 0;
  clear: both;
}

.node-type-property .field-name-field-mapa .field-item,
.node-type-estate .field-name-field-mapa .field-item {
  height: 400px;
}

.page-node-edit.node-type-property .field-name-body,
.page-node-edit.node-type-estate .field-name-body {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.field-name-field-paragraph {
  font-size: 1.5rem;
}

@media (min-width: 555px) {
  .field-name-field-paragraph {
    font-size: 1.25rem;
  }
}

.field-name-field-paragraph .paragraphs-item-texto {
  margin-bottom: 1.5rem;
}

@media (min-width: 333px) {
  .field-name-field-bedrooms {
    width: auto;
  }
}

.field-name-field-bedrooms .field-items {
  display: block;
  text-align: right;
}

.field-name-field-bedrooms .field-items::after {
  width: 45px;
  margin-left: .25em;
  color: #333;
}

@media (min-width: 1222px) {
  .field-name-field-bedrooms .field-items::after {
    font-size: 0.875rem;
  }
}

.view-propiedades-destacadas .grid__content {
  background: #fff;
  padding: 15px;
}

.view-propiedades-destacadas h2 {
  margin: 0 0 10px;
}

.view-propiedades-destacadas .views-field-field-price {
  height: 24px;
  margin: 0 0 16px;
  font-family: 'Open Sans';
  font-weight: bold;
}

.view-propiedades-destacadas .slide__grid {
  padding-bottom: 0;
}

.view-buscador,
.view-property-list {
  margin-top: 20px;
}

.view-buscador .view-filters,
.view-property-list .view-filters {
  background: #fff;
}

.view-buscador .view-content,
.view-property-list .view-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

@media screen and (min-width: 484px) {
  .view-buscador .view-content,
  .view-property-list .view-content {
    grid-auto-rows: 1fr;
  }
}

.view-buscador .views-row,
.view-property-list .views-row {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #d5d5d4;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.view-buscador .views-row .inner,
.view-property-list .views-row .inner {
  position: relative;
  padding: 10px 15px 5px;
  width: auto;
}

.view-buscador .views-row .inner::before, .view-buscador .views-row .inner::after,
.view-property-list .views-row .inner::before,
.view-property-list .views-row .inner::after {
  content: '';
  display: table;
}

.view-buscador .views-row .inner::after,
.view-property-list .views-row .inner::after {
  clear: both;
}

.view-buscador .views-label,
.view-property-list .views-label {
  font-size: 1.5rem;
  color: #777;
  float: left;
  margin-right: 10px;
}

@media (min-width: 555px) {
  .view-buscador .views-label,
  .view-property-list .views-label {
    font-size: 1.25rem;
  }
}

.view-buscador .field-content,
.view-property-list .field-content {
  font-size: 1.5rem;
  margin-bottom: 0;
  font-weight: 600;
  min-height: 24px;
  text-align: right;
}

@media (min-width: 555px) {
  .view-buscador .field-content,
  .view-property-list .field-content {
    font-size: 1.25rem;
  }
}

.view-buscador .field-content::after,
.view-property-list .field-content::after {
  width: 45px;
  margin-left: .25em;
  color: #333;
}

@media (min-width: 1222px) {
  .view-buscador .field-content::after,
  .view-property-list .field-content::after {
    font-size: 0.875rem;
  }
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline.links.operations {
  margin: 0;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  margin-right: 0.375rem;
  display: inline;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
  display: inline-block;
  vertical-align: top;
}

.field .field-label {
  font-weight: normal;
}

.field-label-inline .field-items, .field-label-above .field-items {
  font-size: 1.25rem;
  font-weight: bold;
  color: #000;
}

#edit-points,
#edit-amount {
  text-align: right;
}

@media (min-width: 777px) {
  .form-field-name-field-estate-seller {
    float: right;
    width: 50%;
  }
}

.fieldset-wrapper > .form-type-textfield > input.form-text,
.field-type-email .form-type-textfield > input.form-text {
  width: 100%;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #333;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #333;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.field-name-field-youtube {
  margin-bottom: 1.5rem;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title,
.region-navigation .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.page-views main > .content {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.views-exposed-form label {
  color: #fff;
}

.view-empty {
  background: #fff;
  padding: 0 20px;
  width: 100%;
}

.view-empty::before, .view-empty::after {
  content: '';
  display: table;
}

.view-empty::after {
  clear: both;
}

.node-type-property .box,
.node-type-estate .box {
  background: #fff;
}

.node-type-property main > .content,
.node-type-estate main > .content {
  padding: 0;
  background: none;
}

.view.col-3 .view-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(281px, 1fr));
  grid-auto-rows: 1fr;
}

.view.col-3 .view-content .views-row {
  padding: 1.5rem 1.5rem 0.75rem;
  background: #fff;
}

.view.col-3 .view-content .views-field-field-imagen a {
  display: block;
}

.view.col-3 .view-content .views-field-field-imagen a img {
  width: 100%;
}

.view.col-2 .view-content {
  margin: 0 -0.75rem;
}

.view.col-2 .view-content::before, .view.col-2 .view-content::after {
  content: '';
  display: table;
}

.view.col-2 .view-content::after {
  clear: both;
}

.view.col-2 .view-content .views-field-field-images img {
  width: 100%;
}

.view.col-2 .view-content .views-field-body {
  padding: 0.375rem 0;
}

.view.col-2 .view-content .views-field-body p {
  margin-bottom: 0;
}

.view.col-2 .view-content .views-row .inner {
  margin: 0 0.75rem 1.5rem;
  padding: 1.5rem 1.5rem 0.75rem;
  background: #fff;
}

.view.col-2 .view-content .views-row .inner h2 {
  margin-bottom: 0.75rem;
  text-align: center;
}

.view.col-2 .view-content .views-row .inner .views-field-field-photos {
  margin-bottom: 1.5rem;
  background: silver;
  text-align: center;
}

.view.col-2 .view-content .views-row .inner .views-field-field-photos img {
  display: block;
  margin: 0 auto;
}

@media (min-width: 555px) {
  .view.col-2 .view-content .views-row {
    float: left;
    width: 50%;
  }
  .view.col-2 .view-content .views-row .inner {
    padding: 0.75rem 0.75rem 0.375rem;
  }
}

@media (min-width: 777px) {
  .view.col-2 .view-content .views-row .inner {
    padding: 1.5rem 1.5rem 0.75rem;
  }
}

.view-buscador .views-field {
  display: block;
}

.view-buscador .views-field .field-content {
  display: block;
}

.view-buscador .views-field-field-type {
  font-weight: bold;
}

.view-buscador .views-field-field-cellar, .view-buscador .views-field-field-total-sqm, .view-buscador .views-field-field-useful-sqm, .view-buscador .views-field-field-bedrooms, .view-buscador .views-field-field-bathrooms, .view-buscador .views-field-field-parking {
  display: grid;
  grid-template-columns: auto auto;
  padding: 5px 0;
  border-top: 1px solid #eee;
}

.view-buscador .views-field-field-contract .field-content {
  text-align: center;
  border-top: 0;
}

.view-buscador .views-field-field-disponibilidad {
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 1;
  margin-top: -34px;
}

.view-buscador .views-field-field-disponibilidad .field-content {
  color: #fff;
  text-align: center;
}

.view-buscador .views-field-field-disponibilidad .field-content.available {
  display: none;
}

.view-buscador .views-field-field-disponibilidad .field-content.sold, .view-buscador .views-field-field-disponibilidad .field-content.rented {
  background: #c00;
}

.view-buscador .views-field-field-photos .field-content {
  border-top: 0;
}

@media (min-width: 333px) {
  .view-buscador .views-field-field-photos {
    padding: 0;
  }
}

.view-buscador .views-field-field-estate-address .field-content, .view-buscador .views-field-field-commune .field-content {
  font-size: 1rem;
  border-top: 0;
  text-align: center;
}

@media (min-width: 444px) {
  .view-buscador .views-field-field-bedrooms {
    width: auto;
  }
}

.view-buscador .views-field-field-currency .field-content {
  text-align: center;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.flexslider {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  border: 1px solid #d5d5d4;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.flex-caption {
  text-align: center;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 3px #000;
}

.flex-control-nav {
  bottom: 0;
  z-index: 2;
}

.flex-control-paging li a {
  background: rgba(200, 200, 200, 0.5);
}

.flex-control-paging li a.flex-active {
  background: #fff;
}

.slick li.grid.slide__grid {
  width: auto;
}

.slick__image {
  width: 100%;
}

.slick--view--propiedades-destacadas .views-field-title,
.slick--view--propiedades-destacadas .views-field-field-price {
  text-align: center;
}

.buscador {
  margin: 0;
  padding: 0;
  border: 0;
}

.buscador a {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}

@media (min-width: 333px) {
  .buscador a {
    color: #333;
  }
}

.buscador.collapsed {
  background: none;
}

.buscador.collapsed a {
  background: #fff;
}

@media (min-width: 333px) {
  .buscador.collapsed a {
    background: none;
  }
}

.buscador legend {
  text-align: center;
  display: block;
  margin: 0;
  width: 100%;
}

@media (min-width: 333px) {
  .buscador legend {
    text-align: left;
  }
}

.buscador legend .fieldset-legend {
  background-image: none;
  padding: 0;
}

.buscador legend a {
  padding: 0 0.75rem;
  display: block;
}

@media (min-width: 333px) {
  .buscador legend a {
    background: none;
  }
}

.buscador .fieldset-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media screen and (min-width: 354px) {
  .buscador .fieldset-wrapper {
    padding: 10px;
    background: rgba(255, 255, 255, 0.75);
  }
}

@media screen and (min-width: 1044px) {
  .buscador .fieldset-wrapper {
    grid-gap: 20px;
  }
}

.buscador .views-exposed-form label {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
  color: #fff;
  text-align: left;
}

.buscador .form-item {
  display: block;
  float: none;
  margin-bottom: 0;
  padding-right: 0;
}

.buscador .form-item.form-type-select select {
  width: 100%;
}

.buscador .form-type-select {
  position: relative;
}

.buscador .form-type-select::after {
  top: 0;
  line-height: 50px;
  height: 50px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f107';
  display: block;
  position: absolute;
  right: 15px;
  pointer-events: none;
  color: #0072b9;
}

@media (min-width: 666px) {
  .buscador .form-type-select::after {
    height: 41px;
    line-height: 41px;
  }
}

.buscador select {
  padding: 0 0.375rem;
  appearance: none;
  /* remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 50px;
  line-height: 50px;
  color: #666;
  background: #f3f3f3;
  background: #fff;
  border: 1px solid #aaa;
}

@media (min-width: 666px) {
  .buscador select {
    height: 41px;
    line-height: 41px;
  }
}

.buscador select:hover, .buscador select:active, .buscador select:focus {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0072b9;
  color: #000;
}

.buscador select::-ms-expand {
  display: none;
  /* hide the default arrow in ie10 and ie11 */
}

.buscador .form-submit {
  font-size: 1.5rem;
  padding: 10px 1.5rem;
  font-weight: bold;
  background: #0072b9;
  color: #fff;
  border: 0;
  display: block;
  width: 100%;
}

.buscador .form-submit:hover, .buscador .form-submit:focus {
  background: #0091ec;
}

@media (min-width: 333px) {
  .buscador .form-submit {
    padding: 8px 0;
  }
}

.front .buscador {
  margin-top: 1.5rem;
}

.view-articulos .view-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));
}

.view-articulos .view-content h2 {
  margin: 0;
}

.view-articulos .view-content .views-row article {
  background: #fff;
}

.view-articulos .view-content .views-row article > * {
  padding: 16px;
}

.view-articulos .view-content .views-row article > *.field-name-field-photos {
  padding: 0;
}

.view-articulos .view-content .views-row article > *.field-name-field-photos img {
  width: 100%;
}

.view-articulos .view-content .links.inline {
  margin: 0;
}

.view-eventos .view-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));
}

.view-eventos .view-content h2 {
  margin: 0;
}

.view-eventos .view-content .views-row article {
  background: #fff;
}

.view-eventos .view-content .views-row article > * {
  padding: 16px;
}

.view-eventos .view-content .views-row article > *.field-name-field-photos {
  padding: 0;
}

.view-eventos .view-content .views-row article > *.field-name-field-photos img {
  width: 100%;
}

.view-eventos .view-content .links.inline {
  margin: 0;
}

.breadcrumb {
  font-size: 0.875rem;
  clear: both;
  color: #fff;
  text-shadow: 0 0 5px #000;
}

@media (min-width: 555px) {
  .breadcrumb {
    font-size: 1rem;
    display: block;
  }
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.breadcrumb a:link, .breadcrumb a:visited, .breadcrumb a:active, .breadcrumb a:hover {
  color: #fff;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #333;
}

.menu-name-main-menu,
.main-menu {
  max-width: 1180px;
  margin: 0 auto;
}

.header__secondary-menu .links li {
  padding-right: 0.75rem;
}

.header__secondary-menu .links a {
  font-size: 1.5rem;
  line-height: 1.875rem;
  width: 1em;
  height: 1.25em;
  display: inline-block;
  overflow: hidden;
}

.header__secondary-menu .links a:hover {
  text-decoration: none;
}

.header__secondary-menu .links a:focus, .header__secondary-menu .links a:active {
  color: #b9e4ff;
}

@media (min-width: 555px) {
  .header__secondary-menu .links a[href='/user']::before, .header__secondary-menu .links a[href='/user/logout']::before {
    content: none;
  }
}

@media (min-width: 555px) {
  .header__secondary-menu .links a {
    font-size: 1.25rem;
    line-height: 1.875rem;
    width: auto;
    height: auto;
    display: inline;
    overflow: none;
  }
  .header__secondary-menu .links a:hover {
    text-decoration: underline;
  }
}

.header__secondary-menu .links a::before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 100px;
}

.header__secondary-menu .links a[href='/user']::before {
  content: '\f2bd';
}

.header__secondary-menu .links a[href='/user/logout']::before {
  content: '\f011';
}

.navbar,
.main-navigation .links,
.main-navigation .menu,
.region-navigation .block .menu {
  margin: 0;
  padding: 0;
  text-align: center;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-auto-rows: 2fr;
  background: #fff;
}

@media (min-width: 444px) {
  .navbar,
  .main-navigation .links,
  .main-navigation .menu,
  .region-navigation .block .menu {
    grid-template-columns: repeat(auto-fit, minmax(138px, 1fr));
  }
}

@media (min-width: 555px) {
  .navbar,
  .main-navigation .links,
  .main-navigation .menu,
  .region-navigation .block .menu {
    background: none;
  }
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu,
[dir="rtl"] .region-navigation .block .menu,
.region-navigation .block [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li, .region-navigation .block .menu li {
  font-size: 1.25rem;
  list-style-type: none;
  list-style-image: none;
  float: none;
  margin: 0;
  padding: 0;
}

.navbar__item a,
.navbar li a, .main-navigation .links li a,
.main-navigation .menu li a, .region-navigation .block .menu li a {
  padding: 0.75rem;
  position: relative;
  z-index: 2;
  display: block;
  height: 100%;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li, [dir="rtl"] .region-navigation .block .menu li, .region-navigation .block [dir="rtl"] .menu li {
  float: right;
}

@media (min-width: 777px) {
  .navbar__item,
  .navbar li, .main-navigation .links li,
  .main-navigation .menu li, .region-navigation .block .menu li {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 555px) {
  .navbar,
  .main-navigation .links,
  .main-navigation .menu,
  .region-navigation .block .menu {
    display: flex;
    justify-content: space-between;
    position: static;
    align-items: center;
  }
  .navbar li, .main-navigation .links li,
  .main-navigation .menu li, .region-navigation .block .menu li {
    margin: 0;
    background: none;
  }
}

@media (min-width: 888px) {
  .navbar li a, .main-navigation .links li a,
  .main-navigation .menu li a, .region-navigation .block .menu li a {
    font-weight: bold;
  }
}

@media (min-width: 555px) {
  .region-navigation .block {
    background: #f5f5f5;
  }
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline-block;
  list-style-type: none;
  background-image: none;
}

.pager__item a, .pager__current-item a, .pager-current a, .pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a {
  padding: 0.1875rem 0.75rem;
  display: block;
  background: #fff;
}

.pager__item a.progress-disabled, .pager__current-item a.progress-disabled, .pager-current a.progress-disabled, .pager-item a.progress-disabled,
.pager-first a.progress-disabled,
.pager-previous a.progress-disabled,
.pager-next a.progress-disabled,
.pager-last a.progress-disabled,
.pager-ellipsis a.progress-disabled {
  display: inline-block;
}

.pager__item a:active, .pager__current-item a:active, .pager-current a:active, .pager-item a:active,
.pager-first a:active,
.pager-previous a:active,
.pager-next a:active,
.pager-last a:active,
.pager-ellipsis a:active {
  color: #000;
  background: #fff;
}

.pager__current-item,
.pager .pager-ellipsis,
.pager-current {
  padding: 0.1875rem 0.75rem;
  border: 0;
}

.pager__current-item,
.pager-current {
  background: #fff;
  font-weight: bold;
}

.pager .pager-ellipsis {
  background: #fff;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #333;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  text-align: center;
  background-color: #f5f5f5;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  background-color: #fff;
  background-color: transparent;
  border-bottom: 1px solid #cccccc \0/ie;
}

.tabs-wrapper::before, .tabs-wrapper::after {
  content: '';
  display: table;
}

.tabs-wrapper::after {
  clear: both;
}

@media (min-width: 555px) {
  .tabs-wrapper {
    background: none;
  }
}

.tabs-wrapper .visually-hidden {
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs-wrapper > * {
  margin: 0 auto;
  max-width: 1180px;
}

.tabs-wrapper .tabs {
  margin-top: 0.75rem;
  padding: 0 0.75rem;
  max-width: 1180px;
  float: none;
}

.tabs-wrapper .tabs > .tabs__tab {
  margin-bottom: 0.75rem;
  float: none;
}

.tabs-wrapper .tabs > .tabs__tab.current {
  margin-bottom: 0;
}

@media (min-width: 555px) {
  .tabs-wrapper .tabs > .tabs__tab {
    margin-bottom: 0;
    float: left;
  }
}

.tabs-wrapper .tabs.tabs--secondary {
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  display: block;
}

.tabs-wrapper .tabs.tabs--secondary > .tabs__tab {
  margin: 0;
}

.page-node-delete .tabs-wrapper {
  display: none;
}

.tabs {
  margin: 1.125rem 0 0.75rem;
  line-height: 1.875rem;
  text-transform: uppercase;
  max-width: 1340px;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  list-style: none;
  white-space: nowrap;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  border-bottom-color: transparent;
  overflow: hidden;
  border-bottom: 0 \0/ie;
}

@media (min-width: 555px) {
  .tabs__tab {
    font-size: 1.25rem;
    margin-right: 0.375rem;
    display: inline-block;
  }
}

.tabs__tab:first-child {
  margin-left: 0;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.current, .tabs__tab.is-active {
  border-bottom-color: #fff;
  border-left-color: #cccccc;
  border-right-color: #cccccc;
  border-top: 4px solid #0072b9;
}

.tabs__tab-link {
  padding: 0.375rem;
  background: #fcfcfc;
  color: #999999;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  border: 1px solid #cccccc;
}

@media (min-width: 555px) {
  .tabs__tab-link {
    padding: 0 2.25rem 0.225rem;
    padding-top: 4px;
  }
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
  background: #fff;
  border-color: #cccccc;
  text-decoration: none;
  color: #0072b9;
}

@media (min-width: 555px) {
  .tabs__tab-link:focus, .tabs__tab-link:hover {
    border-bottom: 1px solid #fff;
  }
}

.tabs__tab-link:active, .tabs__tab-link:active:not(.button), .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  color: #0062a0;
  background: #fff;
  text-shadow: none;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #fff;
}

.tabs__tab-link.is-active, .tabs__tab-link--is-active {
  padding-top: 0;
  background: #f5f5f5;
  background: #fff;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  margin-right: 0.375rem;
  margin-bottom: 0.75rem;
  padding: 0 0.375rem;
  border: 1px solid #cccccc;
  color: #333;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: black;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: black 0 1px 0;
  background: #333;
  border-color: black;
}

.vertical-tabs {
  background: #fff;
}

.vertical-tabs fieldset.vertical-tabs-pane {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

div.horizontal-tabs {
  background: #fff;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #333;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 0 0 1.5rem;
}

@media (min-width: 555px) {
  .form-item {
    font-size: 1.25rem;
  }
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-top: 0.375rem;
  font-weight: normal;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-type-password-confirm .description {
  margin-top: 0;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #333;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
