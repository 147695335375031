.slick {

  // &__slide {
  //   width: 33%;
  // }

  li.grid.slide__grid {
    width: auto;
  }

  &__image {
    width: 100%;
  }

  &--view--propiedades-destacadas {

    .views-field-title,
    .views-field-field-price {
      text-align: center;
    }
  }
}
