// Tables
//
// The `<table>` element displays tabular data in rows, columns, and cells.
//
// Markup: tables-table.twig
//
// Weight: 1
//
// Style guide: base.table

table {
  // Add vertical rhythm margins.
  @include margin(0 0 1);
  // Remove most spacing between table cells.
  border-collapse: collapse;
  border-spacing: 0;
  // Prevent cramped-looking tables
  width: 100%;
  border: 1px solid color(border);
}

td,
th {
  // Remove most spacing between table cells.
  padding: .25rem;

  &.checkbox {
    text-align: center;
  }

  @include zen-respond-to(m) {
    font-weight: normal;
  }
}

thead th,
tfoot th {
  font-weight: normal;
  text-align: left;
}

thead th {
  color: color('white');
  background: color('blue');
}

tbody tr {
  background-color: color(row-stripe);

  &.even {
    background-color: color(body-bg);
  }
}
