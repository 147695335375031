// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header-wrapper {
  background: color('grey');
}

.header,
%header {
  // @extend %clearfix;
  display: grid;
  grid-template-columns: auto max-content max-content auto;
  align-items: center;
  max-width: 1160px + $zen-gutters;
  min-height: 80px;
  margin: 0 auto;
  padding-bottom: 15px;

  @include zen-respond-to(xs) {
    grid-template-columns: max-content auto max-content;
    padding-bottom: 0;
  }

  // Wrapping link for logo.
  &__logo {
    @include margin(.5);
    width: 64px;
    grid-column: 2;
    justify-self: end;

    @include zen-respond-to(xs) {
      width: auto;
      grid-column: 1;
      justify-self: end;
      justify-self: unset;
    }

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    margin: 0;
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    align-self: center;
  }

  // The name of the website.
  &__site-name {
    margin: 0;
    padding: 0;
  }

  // The link around the name of the website.
  &__site-link {

    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  &__region {
    grid-column: 1 / -1;

    @include zen-respond-to(xs) {
        grid-column: 3;
    }
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;
    position: relative;
    z-index: 2;

    ul {
      @include margin(.5 0);
    }

    @include rtl() {
      float: left;
    }
  }
}

footer {
  @include margin-top(1);
  @include margin-bottom(1);
  @include padding(.25 0);
  text-align: center;
  background: #999;

  p {
    @include type-layout(m, 1);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.footer {
  @include font-size(s);

  @include zen-respond-to(xs) {
    @include font-size(m);
  }
}
