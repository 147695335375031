// Dependencies.
// Allow layout-center's margin/padding to override layout-3col.
//@import 'layouts/layout-3col/layout-3col';

// Layout Center
//
// All other layouts should be nested inside this centered layout to ensure that
// content does not get too wide on very large screens.
//
// Applying a `.layout-*` class to the same HTML element as `.layout-center`
// would cause that element's grid to mis-align with the rest of the grid on the
// page, but this component will automatically detect the other `.layout-*`
// class and automatically use the `.layout-center--shared-grid` variant
// instead.
//
// .layout-center--shared-grid - If `.layout-center` is applied to the same div
//   as another .layout-* class, then this variant should be used to prevent the
//   shared layout's grid from mis-aligning. Note: Each time a new .layout-*
//   component is created, this component will need to be modified.
//
// Markup: layout-center.twig
//
// Weight: -1
//
// Style guide: layouts.layout-center

.layout-center,
%layout-center {
  @include zen-apply-gutter-padding();
  // margin: 0 auto;
  padding: 0;

  // We wrap the max-width declaration with zen-layout() so we can see the value
  // of $zen-gutters when it is at the larger "xl"-sized media query.
  @include zen-layout(xl) {
    // The max content width is 1140px (the container width minus the gutters.)
    max-width: 1140px + $zen-gutters;
    max-width: none;
  }

  // Since .layout-center has overridden the negative margin on another
  // .layout-* container, the grid children will be misaligned due to
  // .layout-center's gutter padding. So we remove it.
  &--shared-grid {
    padding-left: 0;
    padding-right: 0;
  }
}

main > .content,
.maintenance-page .main-content,

#progress,
.admin,
.cart-empty-page,
.sticky-header,
.sticky-enabled,
.term-listing-heading,
.region-help .block,
.contextual-links-wrapper,
.system-status-report,
.action-links-wrapper {
  @include margin(0 auto);
  @include padding(0 .5);
  max-width: 1140px + $zen-gutters;
}

.messages-wrapper {
  @include margin(0 auto);
  max-width: 1140px + $zen-gutters;
}

main > .content {
  @include padding(1);
  background: color(text-bg);
}

.wrapper-title {
  @include margin(0 auto);
  @include padding-top(.25);

  @include zen-respond-to(xxxl) {
    max-width: 1140px + $zen-gutters;
  }
}

#views-ui-edit-form,
#views-preview-wrapper {
  @include margin(0 1);
}

#edit-options-alter-help-wrapper {
  float: none;
}

#features-form-table {
  @include margin(1 0);
}

main > .profile {
  @include margin-top(1);
}

div.krumo-root,
main > form,
.section-admin .admin-list,
.admin-list {
  @include padding(0 .5);
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px + $zen-gutters;
}

.entity-pos,
#update-manager-update-form,
.confirmation {
  @include margin-top(1);
}

.wizard-trail {
  @include font-size(s);
  @include margin(.5 0);
}

.maintenance-page .header__site-link {

  &:link,
  &:visited {
    color: #333;
  }
}

.region-help {
  @include padding-top(1);
  @include font-size(l);
}
