// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.twig
//
// Style guide: navigation.nav-menu

.nav-menu,
%nav-menu {

  &__item {
    list-style-image: image-url('navigation', 'nav-menu/leaf.svg');
    list-style-type: square;

    &.is-expanded,
    &--is-expanded {
      list-style-image: image-url('navigation', 'nav-menu/expanded.svg');
      list-style-type: circle;
    }

    &.is-collapsed,
    &--is-collapsed {
      list-style-image: image-url('navigation', 'nav-menu/collapsed.svg');
      list-style-type: disc;

      @include rtl() {
        list-style-image: image-url('navigation', 'nav-menu/collapsed-rtl.svg');
      }
    }
  }

  &__link {
    &.is-active,
    &--is-active {
      color: color(menu-active);
    }
  }
}

//
// Drupal selectors.
//

.menu__item {
  @extend %nav-menu__item;

  &.is-expanded {
    @extend %nav-menu__item--is-expanded;
  }

  &.is-collapsed {
    @extend %nav-menu__item--is-collapsed;
  }
}

// The active item in a Drupal menu.
.menu a.active {
  @extend %nav-menu__link--is-active;
}

.menu-name-main-menu,
.main-menu {
  // @include padding(0 .5);
  max-width: 1140px + $zen-gutters;
  margin: 0 auto;
}

.header__secondary-menu .links {

  li {
    @include padding-right(.5);
  }

  a {
    @include type-layout(xl, 1.25);
    width: 1em;
    height: 1.25em;
    display: inline-block;
    overflow: hidden;

    &:hover {
      text-decoration: none;
    }

    &:focus,
    &:active {
      color: lighten(color(link), 50%);
    }

    &[href='/user'],
    &[href='/user/logout'] {

      &::before {

        @include zen-respond-to(xs) {
          content: none;
        }
      }
    }

    @include zen-respond-to(xs) {
      @include type-layout(l, 1.25);
      width: auto;
      height: auto;
      display: inline;
      overflow: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  a::before {
    font-family: 'Font Awesome 5 Free';
    margin-right: 100px;
  }

  a[href='/user']::before {
    content: '\f2bd';
  }

  a[href='/user/logout']::before {
    content: '\f011';
  }
}
