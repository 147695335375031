.page-views main > .content {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.views-exposed-form {

    label {
      // @include visually-hidden();
      color: color('white');
      // text-align: left;
    }
}

.view-empty {
  @include clearfix();
  background: color('white');
  padding: 0 20px;
  width: 100%;
}

.node-type-property,
.node-type-estate {

  .box {
    background: #fff;
  }

  main > .content {
    padding: 0;
    background: none;
  }
}

.view.col-3 .view-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(281px, 1fr));
  grid-auto-rows: 1fr;

  .views-row {
    @include padding(1 1 .5);
    background: color('white');
  }

  .views-field-field-imagen a {
    display: block;

    img {
      width: 100%;
    }
  }
}

// .view.col-3 .view-content {
//   @include clearfix();
//   @include margin(0 -.5);
//
//   .views-row {
//
//     .inner {
//       @include margin(0 .5 1);
//       @include padding(1 1 .5);
//       background: color('white');
//
//       h2 {
//         @include margin-bottom(.5);
//         text-align: center;
//       }
//
//       .views-field-field-photos {
//         @include margin-bottom(1);
//         background: silver;
//         text-align: center;
//
//         img {
//           display: block;
//           margin: 0 auto;
//         }
//       }
//     }
//   }
//
//   @include respond-to(xs) {
//
//     .views-row {
//       float: left;
//       width: (100% / 3);
//
//       .inner {
//         @include padding(.5 .5 .25);
//       }
//     }
//   }
//
//   @include respond-to(m) {
//     .views-row .inner {
//       @include padding(1 1 .5);
//     }
//   }
// }

.view.col-2 .view-content {
  @include clearfix();
  @include margin(0 -.5);

  .views-field-field-images img {
    width: 100%;
  }

  .views-field-body {
    @include padding(.25 0);

    p {
      margin-bottom: 0;
    }
  }

  .views-row {

    .inner {
      @include margin(0 .5 1);
      @include padding(1 1 .5);
      background: color('white');

      h2 {
        @include margin-bottom(.5);
        text-align: center;
      }

      .views-field-field-photos {
        @include margin-bottom(1);
        background: silver;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  @include respond-to(xs) {

    .views-row {
      float: left;
      width: 50%;

      .inner {
        @include padding(.5 .5 .25);
      }
    }
  }

  @include respond-to(m) {

    .views-row .inner {
      @include padding(1 1 .5);
    }
  }
}

.view-buscador .views-field {
  display: block;

  .field-content {
    display: block;
    // border-top: 1px solid #eee;
  }

  &-field-type {
    font-weight: bold;
  }

  // &-field-currency,
  // &-field-type-1,
  // &-field-cellar,
  // &-field-total-sqm,
  // &-field-useful-sqm,
  // &-field-bedrooms,
  // &-field-bathrooms {
  //   //display: table-cell;
  // }

  &-field-cellar,
  &-field-total-sqm,
  &-field-useful-sqm,
  &-field-bedrooms,
  &-field-bathrooms,
  &-field-parking {
    display: grid;
    grid-template-columns: auto auto;
    padding: 5px 0;
    border-top: 1px solid #eee;
  }

  &-field-contract {
    @extend %title-field;
    //@include typeface(condensed);
    //@include typeface(labels);

    .field-content {
      text-align: center;
      border-top: 0;
      //text-align: left;
    }
  }

  &-field-disponibilidad {
    position: absolute;
    left: 15px;
    right: 15px;
    z-index: 1;
    margin-top: -34px;

    .field-content {

      color: #fff;
      text-align: center;

      &.available {
        // background: red;
        display: none;
      }

      &.sold,
      &.rented {
        background: color('red');
      }
      // border-top: 0;
      //text-align: left;
    }
  }

  &-field-photos {
    @extend %photos-field;
    //display: none;

    .field-content {
      border-top: 0;
    }

    @include zen-respond-to(xxxs) {
      padding: 0;
      //display: block;
    }
  }

  &-field-useful-sqm {
    @extend %useful-field;
  }

  &-field-total-sqm {
    @extend %total-field;
  }

  &-field-estate-address,
  &-field-commune {
    @extend %comuna-field;

    .field-content {
      @include font-size(m);
      border-top: 0;
      text-align: center;
    }
  }

  // &-field-bathrooms {
  //   //@extend %bathrooms-field;
  // }

  &-field-bedrooms {
    //@extend %bedrooms-field;

    @include zen-respond-to(xxs) {
      width: auto;
    }
  }

  // &-field-parking {
  //   //@extend %parking-field;
  // }

  // &-field-cellar {
  //   //@extend %cellar-field;
  // }

  &-field-currency {
    @extend %valor-field;

    .field-content {
      text-align: center;
    }
  }

  // &-field-type-1,
  // &-field-total-sqm {
  //   //display: none;
  //
  //   @include zen-respond-to(xs) {
  //     // width: 100%;
  //     //display: block;
  //     // display: table-row;
  //   }
  // }
}
