// The height of the navigation container.
$layout-swap-height: 2 * $base-line-height;

// Layout swap
//
// Uses absolute positioning and selective padding to make 2 elements appear to
// swap places in the visual order while maintaining their original HTML source
// order.
//
// Markup: layout-swap.twig
//
// Style guide: layouts.layout-swap

.layout-swap,
%layout-swap {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 250px;
    background: image-url('layouts', 'layout-swap/images/background.png') repeat-x top;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    @include respond-to(xs) {
      top: 2 * $base-line-height;
    }
  }

  @include respond-to(xs) {
    padding-top: 2 * $base-line-height;
    // Move all the children down to make room for the "top" element.
  }

  &__top {

    @include respond-to(xs) {
      position: absolute;
      top: 0;
      line-height: 48px;
      height: $layout-swap-height;
      width: 100%;
      background: #f5f5f5;
    }
  }
}
