// Pager
//
// Paged navigation is a list of page numbers when more than 1 page of content
// is available.
//
// Markup: pager.twig
//
// Style guide: navigation.pager

.pager,
%pager {
  clear: both;
  padding: 0;
  text-align: center;

  @media print {
    display: none;
  }

  // A page item in the pager list.
  &__item {
    display: inline-block;
    list-style-type: none;
    background-image: none;

    a {
      @include padding(.125 .5);
      display: block;
      background: color(text-bg);

      &.progress-disabled {
        display: inline-block;
      }

      &:active {
        color: color('black');
        background: color('white');
      }
    }
  }

  &__current-item,
  .pager-ellipsis {
    @include padding(.125 .5);
    border: 0;
  }

  // The current page's list item.
  &__current-item {
    @extend %pager__item;
    background: color(text-bg);
    font-weight: bold;
  }

  .pager-ellipsis {
    background: color(text-bg);
  }
}

//
// Drupal selectors.
//

// .pager-item     - A list item containing a page number in the list of pages.
// .pager-first    - The first page's list item.
// .pager-previous - The previous page's list item.
// .pager-next     - The next page's list item.
// .pager-last     - The last page's list item.
// .pager-ellipsis - A concatenation of several list items using an ellipsis.

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  @extend %pager__item;
}

.pager-current {
  @extend %pager__current-item;
}
